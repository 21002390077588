import http from "./http-common";

const contactUs = (form) => {
  return http.post("/contact-us", form);
};

const ContactUs = {
  contactUs,
};

export default ContactUs;
