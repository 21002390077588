import { RETRIEVE_PROJECT } from "../actions/types.js";

const initialState = [];

const projectReducer = (project = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_PROJECT:
			return payload;

		default:
			return project;
	}
};

export default projectReducer;
