import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	retriveArticle,
	deleteArticle,
	deleteAllArticle,
} from "../../../redux/actions/article.action";

const NewsAdminPage = () => {
	const { data } = useSelector(state => state.articleReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(retriveArticle());
	}, [dispatch]);

	const removeById = index => {
		dispatch(deleteArticle(index))
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e);
			});
	};

	const removeAllService = () => {
		dispatch(deleteAllArticle())
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<div className='art'>
			<div className='card'>
				<div className='card-header'>
					<h5>СТАТЬИ/НОВОСТИ/СМИ</h5>
				</div>
				<div className='card-body'>
					<div className='conatner-fluid btn_row'>
						<a href='/addnews' className='btn btn-primary'>
							Добавить
						</a>
						<button
							type='button'
							className='btn btn-danger'
							onClick={() => removeAllService()}
						>
							Удалить все
						</button>
					</div>
					<table className='table table-sm table-bordered table-hover'>
						<thead className='thead-dark'>
							<tr className='text-center'>
								<th scope='col'>#</th>
								<th scope='col'>Название</th>
								<th scope='col'>Категория</th>
								<th scope='col'>Статус</th>
								<th scope='col'>Действие</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map(data => {
									return (
										<tr className='text-center align-baseline' key={data.id}>
											<td key={data.id}>{data.id}</td>
											<td>{data.title_ru}</td>
											<td>
												{data.category_id === 1 ? "Статья/Новость" : "СМИ"}
											</td>
											<td>{data.status === true ? "Опубликован" : "Нет"}</td>
											<td className='conatner-fluid btn_row'>
												<a
													href={"/updatenews/" + data.id}
													className='btn btn-warning'
												>
													<i className='far fa-edit action mr-2' />
												</a>
												<button
													type='button'
													className='btn btn-danger'
													onClick={() => removeById(data.id)}
												>
													<i className='fas fa-trash action' />
												</button>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default NewsAdminPage;
