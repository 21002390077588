import {
	CREATE_ARTICLE,
	RETRIEVE_ARTICLE,
	PUBLISHED_ARTICLE,
	RETRIEVE_ARTICLE_BY_ID,
	DELETE_ALL_ARTICLE,
	DELETE_ARTICLE,
	UPDATE_ARTICLE,
} from "./types";

import Article from "../../services/article.service";

export const createArticle = article => async dispatch => {
	try {
		const res = await Article.create(article);

		dispatch({
			type: CREATE_ARTICLE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const retriveArticle = () => async dispatch => {
	try {
		const res = await Article.getAll();

		dispatch({
			type: RETRIEVE_ARTICLE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const publishedArticle = () => async dispatch => {
	try {
		const res = await Article.getPublished();

		dispatch({
			type: PUBLISHED_ARTICLE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const updateArticleByID = (id, data) => async dispatch => {
	try {
		const res = await Article.update(id, data);

		dispatch({
			type: UPDATE_ARTICLE,
			payload: res.data,
		});

		return Promise.resolve(res.data.message);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retriveArticleByID = id => async dispatch => {
	try {
		const res = await Article.getById(id);

		dispatch({
			type: RETRIEVE_ARTICLE_BY_ID,
			payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteArticle = id => async dispatch => {
	try {
		const res = await Article.remove(id);

		dispatch({
			type: DELETE_ARTICLE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const deleteAllArticle = () => async dispatch => {
	try {
		const res = await Article.removeAll();

		dispatch({
			type: DELETE_ALL_ARTICLE,
			payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};
