import http from "./http-common";

const create = data => {
	console.log("service", data);

	let formData = new FormData();

	formData.append("slug", data.slug);
	formData.append("title_ru", data.title_ru);
	formData.append("title_en", data.title_en);
	formData.append("title_kz", data.title_kz);
	formData.append("body_ru", data.body_ru);
	formData.append("body_en", data.body_en);
	formData.append("body_kz", data.body_kz);
	formData.append("category_id", data.category_id);
	formData.append("status", data.status);
	formData.append("link", data.link);

	for (const key of Object.keys(data.images)) {
		formData.append("images", data.images[key]);
	}
	for (const key of Object.keys(data.attachments)) {
		formData.append("attachments", data.attachments[key]);
	}

	return http.post("/news/", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

const update = (id, data) => {
	let formData = new FormData();
	formData.append("slug", data.slug);
	formData.append("title_ru", data.title_ru);
	formData.append("title_en", data.title_en);
	formData.append("title_kz", data.title_kz);
	formData.append("body_ru", data.body_ru);
	formData.append("body_en", data.body_en);
	formData.append("body_kz", data.body_kz);
	formData.append("category_id", data.category_id);
	formData.append("status", data.status);
	formData.append("link", data.link);

	if (data.images === "") {
		formData.append("images", data.images);
	} else {
		for (const key of Object.keys(data.images)) {
			formData.append("images", data.images[key]);
		}
	}

	if (data.attachments === "") {
		formData.append("attachments", data.attachments);
	} else {
		for (const key of Object.keys(data.attachments)) {
			formData.append("attachments", data.attachments[key]);
		}
	}
	// console.log(id, data);
	// console.log([...formData.entries()]);

	return http.put(`/news/${id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

const getAll = () => {
	return http.get(`/news/`);
};

const getPublished = () => {
	return http.get(`/news/published`);
};

const getById = id => {
	return http.get(`/news/${id}`);
};

const remove = id => {
	return http.delete(`/news/${id}`);
};

const removeAll = () => {
	return http.delete(`/news`);
};

const Article = {
	create,
	update,
	getAll,
	getById,
	remove,
	removeAll,
	getPublished,
};

export default Article;
