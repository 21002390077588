import http from "./http-common";
import authHeader from "./auth-header";

const getAll = () => {
	return http.get(`/users/`, { headers: authHeader() });
};

const remove = id => {
	return http.delete(`/users/${id}`, { headers: authHeader() });
};

const getById = id => {
	return http.get(`/users/${id}`, { headers: authHeader() });
};

// const update = (id, username, email, password) => {
// 	console.log(id);

// 	return http.get(
// 		`/users/${id}`,
// 		{ username, email, password },
// 		{ headers: authHeader() }
// 	);
// };

const Users = {
	getAll,
	remove,
	getById,
	// update,
};

export default Users;
