import { RETRIEVE_COMPANY, RETRIEVE_COMPANY_BY_ID } from "../actions/types.js";

const initialState = [];

const companyReducer = (company = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_COMPANY:
			return payload;

		case RETRIEVE_COMPANY_BY_ID:
			return payload;

		default:
			return company;
	}
};

export default companyReducer;
