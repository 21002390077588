import { RETRIEVE_WIDGET_TEXT } from "../actions/types.js";

const initialState = [];

const widget_textReducer = (widget_text = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_WIDGET_TEXT:
			return payload;

		default:
			return widget_text;
	}
};

export default widget_textReducer;
