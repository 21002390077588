import { SET_LANGUAGE } from "../actions/types";

const localSorageLang = localStorage.getItem("language");

const initialState = {
	language: localSorageLang ? localSorageLang : "EN",
};

const langReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LANGUAGE:
			return {
				...state,
				language: action.payload,
			};
		default:
			return state;
	}
};

export default langReducer;
