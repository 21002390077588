import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createArticle } from "../../../redux/actions/article.action";
import JoditEditor from "jodit-react";

const AddNewsPage = () => {
	const initialArticleState = {
		slug: "",
		title_ru: "",
		title_en: "",
		title_kz: "",
		body_ru: "",
		body_en: "",
		body_kz: "",
		category_id: "1",
		status: false,
		images: "",
		attachments: "",
		link: "",
	};

	const [article, setArticle] = useState(initialArticleState);

	const editor = useRef(null);

	const dispatch = useDispatch();

	const handleInputChange = event => {
		const { name, value } = event.target;

		setArticle({ ...article, [name]: value });
	};

	useEffect(() => {
		console.log(article);
	}, [article]);

	const selectImage = event => {
		setArticle({ ...article, images: event.target.files });
	};

	const selectArticle = event => {
		setArticle({ ...article, attachments: event.target.files });
	};

	const saveArticle = () => {
		dispatch(createArticle(article))
			.then(() => {
				setArticle({
					slug: "",
					title_ru: "",
					title_en: "",
					title_kz: "",
					body_ru: "",
					body_en: "",
					body_kz: "",
					category_id: "1",
					status: false,
					images: "",
					attachments: "",
					link: "",
				});
			})
			.catch(e => {
				console.log(e);
			});
	};

	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
	};

	return (
		<div className='card'>
			<div className='card-back'>
				<a href='/newses'>
					<h6>Вернуться назад</h6>
				</a>
			</div>

			<div className='card-header'>
				<h5>Добавление статьи</h5>
			</div>
			<div className='card-body news_add'>
				<div className='container-fluid'>
					<div className='row row-cols-3'>
						<div className='form-check'>
							<input
								className='form-check-input'
								type='radio'
								name='category_id'
								id='category_id_1'
								defaultChecked
								value='1'
								onChange={handleInputChange}
							/>
							<label className='form-check-label' htmlFor='category_id_1'>
								Статья/Новость
							</label>
						</div>
						<div className='form-check'>
							<input
								className='form-check-input'
								type='radio'
								name='category_id'
								id='category_id_2'
								value='2'
								onChange={handleInputChange}
							/>
							<label className='form-check-label' htmlFor='category_id_2'>
								СМИ
							</label>
						</div>
						<div className='form-check'>
							<input
								className='form-check-input'
								type='checkbox'
								id='status'
								name='status'
								onClick={e =>
									setArticle({ ...article, status: e.target.checked })
								}
							/>
							<label className='form-check-label' htmlFor='defaultCheck1'>
								Опубликован
							</label>
						</div>
					</div>
				</div>

				<div className='mb-3'>
					<label htmlFor='slug' className='form-label'>
						slug
					</label>
					<input
						type='text'
						className='form-control'
						name='slug'
						id='slug'
						placeholder='dlinnoe-nazvanie-novosti-ocen-dlinnoe'
						value={article.slug}
						onChange={handleInputChange}
					/>
				</div>
				<div className='mb-3'>
					<label htmlFor='title_ru' className='form-label'>
						Заголовок на РУ
					</label>
					<input
						type='text'
						className='form-control'
						id='title_ru'
						name='title_ru'
						placeholder='Длинное название новости, очень длинное'
						value={article.title_ru}
						onChange={handleInputChange}
					/>
				</div>
				<div className='mb-3'>
					<label htmlFor='title_kz' className='form-label'>
						Заголовок на КЗ
					</label>
					<input
						type='text'
						className='form-control'
						id='title_kz'
						name='title_kz'
						placeholder='Длинное название новости, очень длинное'
						value={article.title_kz}
						onChange={handleInputChange}
					/>
				</div>
				<div className='mb-3'>
					<label htmlFor='exampleFormControlInput1' className='form-label'>
						Заголовок на EN
					</label>
					<input
						type='text'
						className='form-control'
						id='title_en'
						name='title_en'
						placeholder='Длинное название новости, очень длинное'
						value={article.title_en}
						onChange={e =>
							setArticle({
								...article,
								slug: e.target.value.replaceAll(" ", "-"),
								title_en: e.target.value,
							})
						}
					/>
				</div>

				{article.category_id === "1" ? (
					<>
						<div className='mb-3'>
							<label htmlFor='body_ru' className='form-label'>
								Описание на РУ
							</label>

							<JoditEditor
								ref={editor}
								config={config}
								value={article.body_ru}
								tabIndex={1}
								onBlur={newContent =>
									setArticle({ ...article, body_ru: newContent })
								}
							/>
						</div>
						<div className='mb-3'>
							<label htmlFor='body_en' className='form-label'>
								Описание на EN
							</label>
							<JoditEditor
								ref={editor}
								config={config}
								value={article.body_en}
								tabIndex={1}
								onBlur={newContent =>
									setArticle({ ...article, body_en: newContent })
								}
							/>
						</div>
						<div className='mb-3'>
							<label htmlFor='body_kz' className='form-label'>
								Описание на КЗ
							</label>
							<JoditEditor
								ref={editor}
								config={config}
								value={article.body_kz}
								tabIndex={1}
								onBlur={newContent =>
									setArticle({ ...article, body_kz: newContent })
								}
							/>
						</div>

						<div className='mb-3'>
							<label htmlFor='body_en' className='form-label'>
								Файлы
							</label>
							<div className='input-group mb-3'>
								<input
									type='file'
									multiple
									className='form-control'
									name='attachments'
									id='attachments'
									accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword'
									onChange={selectArticle}
								/>
							</div>
						</div>
					</>
				) : (
					<div className='mb-3'>
						<label htmlFor='link' className='form-label'>
							Ссылка
						</label>
						<textarea
							type='text'
							className='form-control'
							id='link'
							rows='3'
							required
							name='link'
							value={article.link}
							onChange={handleInputChange}
						></textarea>
					</div>
				)}

				<div className='mb-3'>
					<label htmlFor='body_en' className='form-label'>
						Картинки
					</label>
					<div className='input-group mb-3'>
						<input
							type='file'
							multiple
							className='form-control'
							id='images'
							name='images'
							accept='image/heic, image/png, image/jpeg, image/webp'
							onChange={selectImage}
						/>
					</div>
				</div>

				<div className='card-footer'>
					<a href='/newses' className='btn btn-danger'>
						Отмена
					</a>
					<button
						type='button'
						className='btn btn-primary'
						onClick={saveArticle}
					>
						Сохранить
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddNewsPage;
