import http from "./http-common";

const getAll = () => {
	return http.get("/service");
};

const get = id => {
	return http.get(`/service/${id}`);
};

const create = data => {
	let formData = new FormData();
	formData.append("description", data.description);
	formData.append("file", data.file[0]);

	return http.post("/service/", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

const update = (id, data) => {
	let formData = new FormData();
	formData.append("description", data.description);
	formData.append("file", data.file[0]);

	// console.log(id, data);
	// console.log([...formData.entries()]);
	return http.put(`/service/${id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

const remove = id => {
	return http.delete(`/service/${id}`);
};

const removeAll = () => {
	return http.delete(`/service`);
};

const Service = {
	getAll,
	get,
	create,
	update,
	remove,
	removeAll,
};

export default Service;
