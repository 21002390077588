import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveOilPrice } from "../../redux/actions/oil_price.action";

const CarouselOilPrice = () => {
	const [index, setIndex] = useState(0);
	const timeoutRef = useRef(null);

	const dispatch = useDispatch();

	const { data } = useSelector(state => state.oilPriceReducer);

	useEffect(() => {
		dispatch(retrieveOilPrice());
	}, [dispatch]);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() => setIndex(prevIndex => (prevIndex === 3 ? 0 : prevIndex + 1)),
			2500
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	return (
		<div className='slideshowOil'>
			<div
				className='slideshowSliderOil'
				style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
			>
				{data &&
					data.map((data, index) => (
						<div className='slide_oil' key={index}>
							<div className='title'>{data.title}</div>
							<div className='price'>{data.price}</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default CarouselOilPrice;
