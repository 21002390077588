import EN from "./i18n/en.json";
import KZ from "./i18n/kz.json";
import RU from "./i18n/ru.json";

export const translate = (data, key, language) => {
	let langData = {};

	if (language === "EN") {
		langData.name = data.name_en ? data.name_en : "";
		langData.description = data.description_en ? data.description_en : "";
		langData.body = data.body_en ? data.body_en : "";
		langData.about_company = EN.About;
		langData.news = EN.News;
		langData.service = EN.Service;
		langData.group_company = EN.Group_Company;
		langData.rolling_stock = EN["Rolling stock"];
		langData.attachments = EN.Attachments;
		langData.backend = EN.Backend;
		langData.message = EN.Message;
		langData.contact = EN.Contact;
		langData.email = EN["E-mail"];
		langData.login = EN.Login;
		langData.logout = EN.Logout;
		langData.pnf = EN["Page not found"];
		langData.password = EN.password;
		langData.setting = EN.Settings;
		langData.signup = EN.Signup;
		langData.subject = EN.Subject;
		langData.submit = EN.Submit;
		langData.write_to_us = EN["Write to us"];
		langData.norg = EN["Name of organization"];
		langData.cp = EN["The contact person"];
		langData.cn = EN["Contact number"];
		langData.ce = EN["Contact email"];
		langData.ad = EN["Additional details"];
		langData.back = EN.Back;
		langData.partner = EN["Our partner"];
		langData.reward = EN.Reward;
		langData.press = EN.SMI;
	} else if (language === "KZ") {
		langData.name = data.name_kk ? data.name_kk : "";
		langData.description = data.description_kz ? data.description_kz : "";
		langData.body = data.body_kz ? data.body_kz : "";
		langData.about_company = KZ.About;
		langData.news = KZ.News;
		langData.service = KZ.Service;
		langData.group_company = KZ.Group_Company;
		langData.rolling_stock = KZ["Rolling stock"];
		langData.attachments = KZ.Attachments;
		langData.backend = KZ.Backend;
		langData.message = KZ.Message;
		langData.contact = KZ.Contact;
		langData.email = KZ["E-mail"];
		langData.logout = KZ.Logout;
		langData.pnf = KZ["Page not found"];
		langData.password = KZ.password;
		langData.setting = KZ.Settings;
		langData.signup = KZ.Signup;
		langData.subject = KZ.Subject;
		langData.submit = KZ.Submit;
		langData.write_to_us = KZ["Write to us"];
		langData.norg = KZ["Name of organization"];
		langData.cp = KZ["The contact person"];
		langData.cn = KZ["Contact number"];
		langData.ce = KZ["Contact email"];
		langData.ad = KZ["Additional details"];
		langData.back = KZ.Back;
		langData.partner = KZ["Our partner"];
		langData.reward = KZ.Reward;
		langData.press = KZ.SMI;
	} else if (language === "RU") {
		langData.name = data.name_ru ? data.name_ru : "";
		langData.description = data.description_ru ? data.description_ru : "";
		langData.body = data.body_ru ? data.body_ru : "";
		langData.about_company = RU.About;
		langData.news = RU.News;
		langData.service = RU.Service;
		langData.group_company = RU.Group_Company;
		langData.rolling_stock = RU["Rolling stock"];
		langData.attachments = RU.Attachments;
		langData.backend = RU.Backend;
		langData.message = RU.Message;
		langData.contact = RU.Contact;
		langData.email = RU["E-mail"];
		langData.logout = RU.Logout;
		langData.pnf = RU["Page not found"];
		langData.password = RU.password;
		langData.setting = RU.Settings;
		langData.signup = RU.Signup;
		langData.subject = RU.Subject;
		langData.submit = RU.Submit;
		langData.write_to_us = RU["Write to us"];
		langData.norg = RU["Name of organization"];
		langData.cp = RU["The contact person"];
		langData.cn = RU["Contact number"];
		langData.ce = RU["Contact email"];
		langData.ad = RU["Additional details"];
		langData.back = RU.Back;
		langData.partner = RU["Our partner"];
		langData.reward = RU.Reward;
		langData.press = RU.SMI;
	}

	return langData[key];
};
