import React, { useEffect } from "react";
import { Router, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./style/client.css";
import "./style/admin.css";

import cls_btn from "./media/images/close.png";
import mp4 from "./media/video/petroleum.mp4";
import webm from "./media/video/petroleum.webm";
import ogv from "./media/video/petroleum.ogv";

import Navbar from "./pages/component/Navbar";
import SideBar from "./pages/component/SideBar";

import { clearMessage } from "./redux/actions/message";
import { history } from "./helpers/history";

import { useRoute } from "./routes";

const App = () => {
	const { user: currentUser } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		history.listen(location => {
			dispatch(clearMessage()); // clear message when changing location
		});
	}, [dispatch]);

	function closeNav() {
		var element = document.getElementById("clsBtn");
		document.getElementById("myNav").style.width = "0%";
		element.classList.toggle("show");
	}

	const router = useRoute(currentUser);

	if (currentUser) {
		return (
			<Router history={history}>
				{currentUser && (
					<div className='main'>
						<SideBar router={router} />
					</div>
				)}
			</Router>
		);
	} else {
		return (
			<Router history={history}>
				<div className='main'>
					<video
						autoPlay='autoPlay'
						loop='loop'
						muted='muted'
						playsInline='playsInline'
						className='sub_video'
					>
						<source src={mp4} type='video/mp4' />
						<source src={webm} type='video/webm' />
						<source src={ogv} type='video/ogg' />
					</video>

					<div className='overlay' />

					<Link
						to={"/"}
						className='close-button'
						id='clsBtn'
						onClick={closeNav}
					>
						<img src={cls_btn} alt='Close Button' />
					</Link>

					<Navbar />

					<div className='container mt-3'>
						<>{router}</>
					</div>
				</div>
			</Router>
		);
	}
};

export default App;
