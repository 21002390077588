import { RETRIEVE_REWARDS } from "../actions/types.js";

const initialState = [];

const rewardReducer = (reward = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_REWARDS:
			return payload;

		default:
			return reward;
	}
};

export default rewardReducer;
