import React from "react";

const Email = () => {
	return (
		<>
			<div>Пока не придумал</div>
			<div className='card-footer'>
				<button type='button' className='btn btn-primary'>
					Сохранить
				</button>
			</div>
		</>
	);
};

export default Email;
