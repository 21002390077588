import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import LoginPage from "./pages/ClientPage/Login/LoginPage";
import RegisterPage from "./pages/AdminPage/Profile/RegisterPage";
import ProfilePage from "./pages/AdminPage/Profile/ProfilePage";
import ServicesPage from "./pages/AdminPage/Services/ServicesPage";
import NewsAdminPage from "./pages/AdminPage/News/NewsAdminPage";
import ContactAdminPage from "./pages/AdminPage/Contact/ContactAdminPage";
import AddNewsPage from "./pages/AdminPage/News/AddNewsPage";
import UpdateNewsPage from "./pages/AdminPage/News/UpdateNewsPage";
import Loading from "./pages/component/loading";

// import ServicePage from "./pages/ClientPage/Service/SerivicePage";
// import ProjectPage from "./pages/ClientPage/Project/ProjectPage";
// import NewsPage from "./pages/ClientPage/News/NewsPage";
// import NewsID from "./pages/ClientPage/News/NewsID";
// import ContactPage from "./pages/ClientPage/Contact/ContactPage";
// import Page_404 from "./pages/ClientPage/404/Page_404";
// import CompanyID from "./pages/ClientPage/Company/CompanyID";
// import AboutCompany from "./pages/ClientPage/AboutCompany/AboutCompany";
// import HomePage from "./pages/ClientPage/Home/HomePage";
// import Company from "./pages/ClientPage/Company/Company";

const Company = lazy(() => import("./pages/ClientPage/Company/Company"));
const ProjectPage = lazy(() =>
	import("./pages/ClientPage/Project/ProjectPage")
);
const NewsPage = lazy(() => import("./pages/ClientPage/News/NewsPage"));
const ServicePage = lazy(() =>
	import("./pages/ClientPage/Service/SerivicePage")
);
const NewsID = lazy(() => import("./pages/ClientPage/News/NewsID"));
const ContactPage = lazy(() =>
	import("./pages/ClientPage/Contact/ContactPage")
);
const Page_404 = lazy(() => import("./pages/ClientPage/404/Page_404"));
const CompanyID = lazy(() => import("./pages/ClientPage/Company/CompanyID"));
const AboutCompany = lazy(() =>
	import("./pages/ClientPage/AboutCompany/AboutCompany")
);
const HomePage = lazy(() => import("./pages/ClientPage/Home/HomePage"));

export const useRoute = currentUser => {
	if (currentUser) {
		return (
			<Switch>
				<Route exact path='/profile' component={ProfilePage} />
				<Route exact path='/register' component={RegisterPage} />
				<Route exact path='/services' component={ServicesPage} />
				<Route exact path='/newses' component={NewsAdminPage} />
				<Route exact path='/addnews' component={AddNewsPage} />
				<Route exact path='/updatenews/:id' component={UpdateNewsPage} />
				<Route exact path='/contacts' component={ContactAdminPage} />
				<Redirect to='/profile' />
			</Switch>
		);
	} else {
		return (
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path='/' component={HomePage} />
					<Route exact path='/login' component={LoginPage} />
					<Route exact path='/service' component={ServicePage} />
					<Route exact path='/project' component={ProjectPage} />
					<Route exact path='/news' component={NewsPage} />
					<Route exact path='/news/:id' component={NewsID} />
					<Route exact path='/contacts' component={ContactPage} />
					<Route exact path='/company' component={Company} />
					<Route exact path='/company/:id' component={CompanyID} />
					<Route exact path='/about_company' component={AboutCompany} />
					<Route path='*' component={Page_404} />
				</Switch>
			</Suspense>
		);
	}
};
