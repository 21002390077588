import React from "react";

const Loading = () => {
	return (
		<div className='centered'>
			<div className='loader'></div>
		</div>
	);
};

export default Loading;
