import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import serviceReducer from "./serviceReducer";
import articleReducer from "./articleReducer";
import companyReducer from "./companyReducer";
import langReducer from "./langReducer";
import widget_textReducer from "./widget_textReducer";
import about_companyReducer from "./about_companyReducer";
import rewardReducer from "./rewardReducer";
import partnerReducer from "./partnerReducer";
import projectReducer from "./projectReducer";
import ratesReducer from "./ratesReducer";
import oilPriceReducer from "./oilPriceReducer";
import usersReducer from "./userReduce";

export default combineReducers({
	auth,
	message,
	serviceReducer,
	articleReducer,
	companyReducer,
	langReducer,
	widget_textReducer,
	about_companyReducer,
	rewardReducer,
	partnerReducer,
	projectReducer,
	ratesReducer,
	oilPriceReducer,
	usersReducer,
});
