import { RETRIEVE_RATES } from "./types";

import ServiceRates from "../../services/rates.services";

export const retrieveRates = () => async dispatch => {
	try {
		const res = await ServiceRates.getAll();

		dispatch({
			type: RETRIEVE_RATES,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
