import React from "react";
import logo from "../../media/images/logo.png";
import { useDispatch } from "react-redux";
import { logut } from "../../redux/actions/auth";

const SideBar = props => {
	const dispatch = useDispatch();

	const logOut = () => {
		dispatch(logut());
	};

	const toggleMenu = () => {
		let toggle = document.querySelector(".toggle");
		let sidebar = document.querySelector(".sidebar");
		let main_content = document.querySelector(".main_content");
		let logo = document.querySelector(".logo");
		toggle.classList.toggle("active");
		sidebar.classList.toggle("active");
		main_content.classList.toggle("active");
		logo.classList.toggle("active");
	};

	return (
		<div className='wrapped'>
			<div className='sidebar'>
				<ul>
					<li className='logo'>
						<a href={"/profile"}>
							<img src={logo} alt='Logo' />
						</a>
					</li>
					<li>
						<a href='/profile'>
							<span className='icon'>
								<i className='fas fa-user'></i>
							</span>
							<span className='title'>Профиль</span>
						</a>
					</li>
					<li>
						<a href='/services'>
							<span className='icon'>
								<i className='fas fa-wrench'></i>
							</span>
							<span className='title'>Услуги</span>
						</a>
					</li>
					<li>
						<a href='/newses'>
							<span className='icon'>
								<i className='fas fa-newspaper'></i>
							</span>
							<span className='title'>Новости</span>
						</a>
					</li>
					<li>
						<a href='/contacts'>
							<span className='icon'>
								<i className='fas fa-compass'></i>
							</span>
							<span className='title'>Контакты</span>
						</a>
					</li>
					{/* <li>
						<a href='/register'>
							<span className='icon'>
								<i className='fas fa-user-plus'></i>
							</span>
							<span className='title'>Регистрация</span>
						</a>
					</li> */}
				</ul>
			</div>

			<div className='main_content'>
				<div className='topbar'>
					<div className='toggle' onClick={() => toggleMenu()} />
					<div className='logout'>
						<a href='/' className='nav-link btn btn-primary' onClick={logOut}>
							Выйти
						</a>
					</div>
				</div>
				<div className='content_admin'>{props.router}</div>
			</div>
		</div>
	);
};

export default SideBar;
