import { CONTACT_US } from "../actions/types";

import ContactUs from "../../services/contactUs.service";

export const contactUsAction = form => async dispatch => {
	try {
		console.log(form);

		const res = await ContactUs.contactUs(form);

		dispatch({
			type: CONTACT_US,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
