import { RETRIEVE_PARTNER } from "../actions/types.js";

const initialState = [];

const partnerReducer = (partner = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_PARTNER:
			return payload;

		default:
			return partner;
	}
};

export default partnerReducer;
