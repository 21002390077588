import { RETRIVE_ALL_USERS, DELETE_USERS_BY_ID } from "../actions/types.js";

const initialState = [];

const usersReducer = (users = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIVE_ALL_USERS:
			return payload;

		case DELETE_USERS_BY_ID:
			return payload;

		default:
			return users;
	}
};

export default usersReducer;
