import { RETRIEVE_WIDGET_TEXT, UPDATE_WIDGET_TEXT } from "./types";
import widget_text from "../../services/widget_text.service";

export const retrieveWidgetByKey = key => async dispatch => {
	try {
		const res = await widget_text.getByKey(key);

		dispatch({
			type: RETRIEVE_WIDGET_TEXT,
			payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const updateWidgetByKey = (key, data) => async dipatch => {
	try {
		const res = await widget_text.update(key, data);
		dipatch({
			type: UPDATE_WIDGET_TEXT,
			payload: res.data,
		});
		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};
