import {
	CREATE_SERVICES,
	RETRIEVE_SERVICES,
	UPDATE_SERVICES,
	DELETE_ALL_SERVICES,
	DELETE_SERVICES,
} from "../actions/types";

const initialState = [];

const serviceReducer = (service = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case CREATE_SERVICES:
			return payload;

		case RETRIEVE_SERVICES:
			return payload;

		case UPDATE_SERVICES:
			return payload;

		case DELETE_SERVICES:
			return payload;

		case DELETE_ALL_SERVICES:
			return [];

		default:
			return service;
	}
};

export default serviceReducer;
