import {
	CREATE_SERVICES,
	RETRIEVE_SERVICES,
	UPDATE_SERVICES,
	DELETE_ALL_SERVICES,
	DELETE_SERVICES,
} from "./types";

import ServiceDataService from "../../services/service.service";

export const createService = (description, file) => async dispatch => {
	try {
		const res = await ServiceDataService.create({ description, file });
		dispatch({
			type: CREATE_SERVICES,
			payload: res.data,
		});
		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retrieveService = () => async dispatch => {
	try {
		const res = await ServiceDataService.getAll();

		dispatch({
			type: RETRIEVE_SERVICES,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const updateService = (id, data) => async dispatch => {
	try {
		const res = await ServiceDataService.update(id, data);
		dispatch({
			type: UPDATE_SERVICES,
			payload: res.data,
		});
		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteService = id => async dispatch => {
	try {
		const res = await ServiceDataService.remove(id);

		dispatch({
			type: DELETE_SERVICES,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const deleteAllService = () => async dispatch => {
	try {
		const res = await ServiceDataService.removeAll();

		dispatch({
			type: DELETE_ALL_SERVICES,
			payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};
