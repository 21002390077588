import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router";
import {
	deleteAllService,
	deleteService,
	retrieveService,
} from "../../../redux/actions/service.action";
import AddService from "./AddService";
import UpdateService from "./UpdateService";

const ServicesPage = () => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const { data } = useSelector(state => state.serviceReducer);
	const dispatch = useDispatch();

	// let history = useHistory();

	useEffect(() => {
		dispatch(retrieveService());
	}, [dispatch]);

	const setActiveService = (description, index) => {
		setCurrentIndex(index);
	};

	const removeService = index => {
		dispatch(deleteService(index))
			.then(() => {
				// history.go(0);
			})
			.catch(e => {
				console.log(e);
			});
	};

	const removeAllService = () => {
		dispatch(deleteAllService())
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<div className='card'>
			<div className='card-header'>
				<h5>УСЛУГИ</h5>
			</div>
			<div className='card-body'>
				<div className='conatner-fluid btn_row'>
					<button
						type='button'
						className='btn btn-primary'
						data-bs-toggle='modal'
						data-bs-target='#serviceAdd'
					>
						Добавить
					</button>
					<AddService />
					<button
						type='button'
						className='btn btn-danger'
						onClick={removeAllService}
					>
						Удалить все
					</button>
				</div>
				<table className='table table-sm table-bordered table-hover'>
					<thead className='thead-dark'>
						<tr className='text-center'>
							<th scope='col'>#</th>
							<th scope='col'>Название</th>
							<th scope='col'>Картинка</th>
							<th scope='col'>Действие</th>
						</tr>
					</thead>
					<tbody>
						{data &&
							data.map(data => {
								return (
									<tr className='text-center align-baseline' key={data.id}>
										<td>{data.id}</td>
										<td>{data.name_ru}</td>
										<td>
											<img
												src={
													process.env.REACT_APP_URL + data.path + data.name_img
												}
												alt={data.name_img}
											/>
										</td>
										<td
											onClick={() =>
												setActiveService(data.description, data.id)
											}
										>
											<span
												data-bs-toggle='modal'
												data-bs-target='#serviceUpdate'
												className='btn btn-warning'
											>
												<i className='far fa-edit action mr-2' />
											</span>
											<button
												type='button'
												className='btn btn-danger'
												onClick={() => removeService(data.id)}
											>
												<i className='fas fa-trash action' />
											</button>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>

				<UpdateService serviceId={currentIndex} />
			</div>
		</div>
	);
};

export default ServicesPage;
