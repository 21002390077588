import {
	CREATE_ARTICLE,
	UPDATE_ARTICLE,
	PUBLISHED_ARTICLE,
	RETRIEVE_ARTICLE,
	DELETE_ALL_ARTICLE,
	DELETE_ARTICLE,
	RETRIEVE_ARTICLE_BY_ID,
} from "../actions/types.js";

const initialState = [];

const articleReducer = (article = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case CREATE_ARTICLE:
			return payload;

		case UPDATE_ARTICLE:
			return payload;

		case RETRIEVE_ARTICLE:
			return payload;

		case PUBLISHED_ARTICLE:
			return payload;

		case RETRIEVE_ARTICLE_BY_ID:
			return payload;

		case DELETE_ARTICLE:
			return payload;

		case DELETE_ALL_ARTICLE:
			return [];

		default:
			return article;
	}
};

export default articleReducer;
