import { OIL_PRICE } from "./types";

import ServiceOilPrice from "../../services/oil_price.service";

export const retrieveOilPrice = () => async dispatch => {
	try {
		const res = await ServiceOilPrice.getAll();

		dispatch({
			type: OIL_PRICE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
