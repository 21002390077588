import { RETRIEVE_RATES } from "../actions/types";

const initialState = [];

const ratesReducer = (rates = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_RATES:
			return payload;

		default:
			return rates;
	}
};

export default ratesReducer;
