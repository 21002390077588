import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	retrieveUsers,
	deleteUsers,
} from "../../../redux/actions/users.action";
import RegistPage from "../Profile/RegisterPage";
// import UpdateUser from "../Profile/UpdateUser";

const ProfilePage = () => {
	const { user: currentUser } = useSelector(state => state.auth);
	// const [currentRow, setCurrentRow] = useState();

	const { data } = useSelector(state => state.usersReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(retrieveUsers());
	}, [dispatch]);

	if (!currentUser) {
		return <Redirect to='/login' />;
	}

	const removeById = index => {
		dispatch(deleteUsers(index)).catch(e => {
			console.log(e);
		});
	};

	return (
		<div className='row'>
			<div className='col'>
				<div className='card'>
					<header className='jumbotron'>
						<h3>
							<strong>{currentUser.username}</strong>
						</h3>
					</header>
					<p>
						<strong>Token:</strong> {currentUser.accessToken.substring(0, 20)}{" "}
						...{" "}
						{currentUser.accessToken.substr(
							currentUser.accessToken.length - 20
						)}
					</p>
					<p>
						<strong>Id:</strong> {currentUser.id}
					</p>
					<p>
						<strong>Email:</strong> {currentUser.email}
					</p>
					<strong>Authorities:</strong>
					<ul>
						{currentUser.roles &&
							currentUser.roles.map((role, index) => (
								<li key={index}>{role}</li>
							))}
					</ul>
				</div>
			</div>
			<div className='col'>
				<div className='card'>
					<div className='card-header'>
						<h3>Пользователи</h3>
					</div>
					<div className='card-body'>
						<div className='conatner-fluid btn_row'>
							<button
								type='button'
								className='btn btn-primary'
								data-bs-toggle='modal'
								data-bs-target='#serviceUser'
							>
								Добавить
							</button>
							<RegistPage />
						</div>
						<table className='table table-sm table-bordered table-hover'>
							<thead className='thead-dark'>
								<tr className='text-center'>
									<th scope='col'>#</th>
									<th scope='col'>Название</th>
									<th scope='col'>Почта</th>
									<th scope='col'>Действие</th>
								</tr>
							</thead>
							<tbody>
								{data &&
									data.map(data => {
										return (
											<tr className='text-center align-baseline' key={data.id}>
												<td key={data.id}>{data.id}</td>
												<td>{data.username}</td>
												<td>{data.email}</td>
												<td className='conatner-fluid btn_row'>
													{/* <button
														type='button'
														className='btn btn-warning'
														data-bs-toggle='modal'
														data-bs-target='#updateUser'
														onClick={() => setCurrentRow(data.id)}
													>
														<i className='far fa-edit action mr-2' />
													</button>

													<UpdateUser id={currentRow} /> */}

													<button
														type='button'
														className='btn btn-danger'
														onClick={() => removeById(data.id)}
													>
														<i className='fas fa-trash action' />
													</button>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePage;
