import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { retrieveWidgetByKey } from "../../../redux/actions/widget_text.action";
import JoditEditor from "jodit-react";

const Contacts = () => {
	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
	};

	const editor = useRef(null);

	const dispatch = useDispatch();

	const initialContactState = {
		body_ru: "",
		body_en: "",
		body_kz: "",
	};

	const [contact, setContact] = useState(initialContactState);

	useEffect(() => {
		dispatch(retrieveWidgetByKey("contacts-text-iframe"))
			.then(data => {
				setContact({
					body_en: data.value[0].body_en,
					body_kz: data.value[0].body_kz,
					body_ru: data.value[0].body_ru,
				});
			})
			.catch(e => {
				console.log(e);
			});
	}, [dispatch]);

	return (
		<>
			<div className='mb-3'>
				<label htmlFor='body_ru' className='form-label'>
					Описание на РУ
				</label>

				<JoditEditor
					ref={editor}
					config={config}
					tabIndex={1}
					value={contact.body_ru}
				/>
			</div>
			<br />
			<div className='mb-3'>
				<label htmlFor='body_en' className='form-label'>
					Описание на EN
				</label>

				<JoditEditor
					ref={editor}
					config={config}
					tabIndex={1}
					value={contact.body_en}
				/>
			</div>
			<br />
			<div className='mb-3'>
				<label htmlFor='body_en' className='form-label'>
					Описание на KZ
				</label>

				<JoditEditor
					ref={editor}
					config={config}
					tabIndex={1}
					value={contact.body_kz}
				/>
			</div>

			<div className='card-footer'>
				<button type='button' className='btn btn-primary'>
					Сохранить
				</button>
			</div>
		</>
	);
};

export default Contacts;
