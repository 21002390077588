import {
	RETRIVE_ALL_USERS,
	DELETE_USERS_BY_ID,
	RETRIVE_USERS_BY_ID,
} from "./types";
import Users from "../../services/user.service";

export const retrieveUsers = () => async dispatch => {
	try {
		const res = await Users.getAll();

		dispatch({
			type: RETRIVE_ALL_USERS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const deleteUsers = id => async dispatch => {
	try {
		const res = await Users.remove(id);

		dispatch({
			type: DELETE_USERS_BY_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const retrieveUsersById = id => async dispatch => {
	try {
		const res = await Users.getById(id);

		dispatch({
			type: RETRIVE_USERS_BY_ID,
			payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const UpdateUsersById =
	(id, username, email, password) => async dispatch => {
		try {
			const res = await Users.update(id, username, email, password);

			dispatch({
				type: RETRIVE_USERS_BY_ID,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};
