import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import JoditEditor from "jodit-react";
import {
	retrieveWidgetByKey,
	updateWidgetByKey,
} from "../../../redux/actions/widget_text.action";

const Location = () => {
	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
	};

	const editor = useRef(null);

	const dispatch = useDispatch();

	const initialLocationState = {
		body_ru: "",
		body_en: "",
		body_kz: "",
		id: "",
	};

	const [location, setLocation] = useState(initialLocationState);

	useEffect(() => {
		dispatch(retrieveWidgetByKey("contacts-text-iframe"))
			.then(data => {
				setLocation({
					body_en: data.value[1].body_en,
					body_kz: data.value[1].body_kz,
					body_ru: data.value[1].body_ru,
					id: 8,
				});
			})
			.catch(e => {
				console.log(e);
			});
	}, [dispatch]);

	const updateLocation = () => {
		dispatch(updateWidgetByKey("contacts-text-iframe", location))
			.then(data => {
				setLocation({
					body_ru: data.value[1].body_ru,
					body_kz: data.value[1].body_kz,
					body_en: data.value[1].body_en,
				});
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<>
			<div className='mb-3'>
				<label htmlFor='location' className='form-label'>
					Вставьте iframe
				</label>
				<JoditEditor
					ref={editor}
					config={config}
					tabIndex={1}
					value={location.body_ru}
					onBlur={newIframe =>
						setLocation({
							...location,
							body_ru: newIframe,
							body_kz: newIframe,
							body_en: newIframe,
						})
					}
				/>
			</div>

			<div className='card-footer'>
				<button
					type='button'
					className='btn btn-primary'
					onClick={updateLocation}
				>
					Сохранить
				</button>
			</div>
		</>
	);
};

export default Location;
