import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createService } from "../../../redux/actions/service.action";

function AddService() {
	const initialServiceState = {
		id: null,
		description: "",
		file: undefined,
	};

	const [service, setService] = useState(initialServiceState);

	const dispatch = useDispatch();

	const handleInputChange = event => {
		const { name, value } = event.target;
		setService({ ...service, [name]: value });
	};

	const selectFile = event => {
		setService({ ...service, file: event.target.files });
	};

	const saveService = () => {
		const { description, file } = service;

		dispatch(createService(description, file))
			.then(() => {
				setService({
					description: "",
					file: undefined,
				});
			})
			.finally(() => {
				const closeBtn = document.getElementById("close-button");
				closeBtn.click();
				// window.location.reload();
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<div
			className='modal fade'
			id='serviceAdd'
			tabIndex='-1'
			aria-labelledby='ModalLabel'
			aria-hidden='true'
		>
			<div className='modal-dialog'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='ModalLabel'>
							Добавить услугу
						</h5>
					</div>

					<div className='modal-body'>
						<div className='mb-3'>
							<label htmlFor='description' className='form-label'>
								Описание
							</label>
							<textarea
								type='text'
								className='form-control'
								id='description'
								required
								rows='3'
								name='description'
								value={service.description}
								onChange={handleInputChange}
							/>
							<label className='btn btn-default'>
								<input type='file' name='file' onChange={selectFile} />
							</label>
						</div>
					</div>

					<div className='modal-footer'>
						<button
							id='close-button'
							type='button'
							className='btn btn-secondary'
							data-bs-dismiss='modal'
						>
							Отменить
						</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={saveService}
						>
							Сохранить изменения
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddService;
