import http from "./http-common";

const getByKey = key => {
	return http.get(`/widget-text/${key}`);
};

const update = (key, data) => {
	console.log(data.id);
	return http.put(`/widget-text/${key}`, data);
};

const widget_text = {
	getByKey,
	update,
};

export default widget_text;
