import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateService } from "../../../redux/actions/service.action";
import ServiceDataService from "../../../services/service.service";

function UpdateService(props) {
	const initialServiceState = {
		id: null,
		description: "",
		file: undefined,
	};

	const [currentService, setCurrentService] = useState(initialServiceState);

	const dispatch = useDispatch();

	useEffect(() => {
		getService(props.serviceId);
	}, [props.serviceId]);

	const getService = id => {
		ServiceDataService.get(id)
			.then(respose => {
				if (respose.data.data.length > 0) {
					setCurrentService(respose.data.data[0]);
				}
				// console.log(respose);
			})
			.catch(e => {
				console.log(e);
			});
	};

	const handleInputChange = event => {
		const { name, value } = event.target;
		setCurrentService({ ...currentService, [name]: value });
	};

	const selectFile = event => {
		setCurrentService({ ...currentService, file: event.target.files });
	};

	const updateContent = () => {
		const { id } = currentService;
		currentService.file
			? dispatch(updateService(id, currentService))
					.then(response => {
						setCurrentService({ id: null, description: "", file: undefined });
						console.log(response.message);
					})
					.finally(() => {
						const closeBtn = document.getElementById("close-button");
						closeBtn.click();
					})
					.catch(e => {
						console.log(e);
					})
			: console.log("Должен быть выбран файл");
	};

	return (
		<div
			className='modal fade'
			id='serviceUpdate'
			tabIndex='-1'
			role='dialog'
			aria-labelledby='serviceModalUpdate'
			aria-hidden='true'
		>
			<div className='modal-dialog' role='document'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='serviceModalUpdate'>
							Обновить услугу
						</h5>
					</div>
					<div className='modal-body'>
						<label htmlFor='description' className='form-label'>
							Описание
						</label>
						<textarea
							type='text'
							className='form-control'
							id='description'
							required
							rows='3'
							name='description'
							value={currentService.description}
							onChange={handleInputChange}
						/>
						<label className='btn btn-default'>
							<input type='file' name='file' onChange={selectFile} />
						</label>
					</div>
					<div className='modal-footer'>
						<button
							id='close-button'
							type='button'
							className='btn btn-secondary'
							data-bs-dismiss='modal'
						>
							Отменить
						</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={updateContent}
						>
							Сохранить
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpdateService;
