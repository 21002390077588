import { OIL_PRICE } from "../actions/types";

const initialState = [];

const oilPriceReducer = (oilprice = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case OIL_PRICE:
			return payload;

		default:
			return oilprice;
	}
};

export default oilPriceReducer;
