export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_LANGUAGE = "SET_LANGUAGE";

// -----Services----------------------------
export const CREATE_SERVICES = "CREATE_SERVICES";
export const RETRIEVE_SERVICES = "RETRIEVE_SERVICES";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const DELETE_SERVICES = "DELETE_SEREVICES";
export const DELETE_ALL_SERVICES = "DELETE_ALL_SERVICES";

// ------Article-----------------------------------
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const RETRIEVE_ARTICLE = "RETRIEVE_ARTICLE";
export const PUBLISHED_ARTICLE = "PUBLISHED_ARTICLE";
export const RETRIEVE_ARTICLE_BY_ID = "RETRIEVE_ARTICLE_BY_ID";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const DELETE_ALL_ARTICLE = "DELETE_ALL_ARTICLE";

// ------Company----------------------------------
export const CREATE_COMPANY = "CREATE_COMPANY";
export const RETRIEVE_COMPANY = "RETRIEVE_COMPANY";
export const RETRIEVE_COMPANY_BY_ID = "RETRIEVE_COMPANY_BY_ID";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_ALL_COMPANY = "DELETE_ALL_COMPANY";

// ------Contact Us-------------------------------
export const CONTACT_US = "CONTACT_US";

//-------Widget_Text------------------------------
export const RETRIEVE_WIDGET_TEXT = "RETRIEVE_WIDGET_TEXT";
export const UPDATE_WIDGET_TEXT = "UPDATE_WIDGET_TEXT";

//-------About Company-------------------------------
export const RETRIEVE_ABOUT_COMPANY = "RETRIEVE_ABOUT_COMPANY";

//-------Partner-------------------------------
export const RETRIEVE_PARTNER = "RETRIEVE_PARTNER";

//-------Rewards-------------------------------
export const RETRIEVE_REWARDS = "RETRIEVE_REWARDS";

//-------Article-------------------------------
export const RETRIEVE_PRESS = "RETRIEVE_PRESS";

//-------Project-------------------------------
export const RETRIEVE_PROJECT = "RETRIEVE_PROJECT";

//-------Rates-------------------------------
export const RETRIEVE_RATES = "RETRIEVE_RATES";

//-------Oil Price-----------------------------
export const OIL_PRICE = "OIL_PRICE";
// ------Users---------------------------------
export const RETRIVE_ALL_USERS = "RETRIVE_ALL_USERS";
export const RETRIVE_USERS_BY_ID = "RETRIVE_USERS_BY_ID";
export const DELETE_USERS_BY_ID = "DELETE_USERS_BY_ID";
export const UPDATE_USERS = "UPDATE_USERS";
