import http from "./http-common";

const getAll = () => {
	return http.get("/rates");
};

const Rates = {
	getAll,
};

export default Rates;
