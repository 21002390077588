import http from "./http-common";

const getAll = () => {
	return http.get("/oil_price");
};

const Oil_Price = {
	getAll,
};

export default Oil_Price;
