import React from "react";
import Contcts from "./Contacts";
import Location from "./Location";
import Email from "./Email";

const ContactAdminPage = () => {
	return (
		<div className='card'>
			<div className='card-header'>
				<h5>КОНТАКТЫ</h5>
			</div>
			<div className='card-body'>
				<div className='container-fluid'>
					<ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
						<li className='nav-item' role='presentation'>
							<a
								className='nav-link active'
								id='pills-contacts-tab'
								data-toggle='pill'
								href='#pills-contacts'
								role='tab'
								aria-controls='pills-contacts'
								aria-selected='true'
							>
								Контакты
							</a>
						</li>
						<li className='nav-item' role='presentation'>
							<a
								className='nav-link'
								id='pills-location-tab'
								data-toggle='pill'
								href='#pills-location'
								role='tab'
								aria-controls='pills-location'
								aria-selected='false'
							>
								Карта
							</a>
						</li>
						<li className='nav-item' role='presentation'>
							<a
								className='nav-link'
								id='pills-email-tab'
								data-toggle='pill'
								href='#pills-email'
								role='tab'
								aria-controls='pills-email'
								aria-selected='false'
							>
								Почта
							</a>
						</li>
					</ul>
					<div className='tab-content' id='pills-tabContent'>
						<div
							className='tab-pane fade show active'
							id='pills-contacts'
							role='tabpanel'
							aria-labelledby='pills-contacts-tab'
						>
							<Contcts />
						</div>
						<div
							className='tab-pane fade'
							id='pills-location'
							role='tabpanel'
							aria-labelledby='pills-location-tab'
						>
							<Location />
						</div>
						<div
							className='tab-pane fade'
							id='pills-email'
							role='tabpanel'
							aria-labelledby='pills-email-tab'
						>
							<Email />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactAdminPage;
