import { RETRIEVE_ABOUT_COMPANY } from "../actions/types.js";

const initialState = [];

const about_companyReducer = (about_company = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case RETRIEVE_ABOUT_COMPANY:
			return payload;

		default:
			return about_company;
	}
};

export default about_companyReducer;
