import React, { useState } from "react";
import KZ from "../../media/images/kk.png";
import RU from "../../media/images/ru.png";
import EN from "../../media/images/en.png";
import logo from "../../media/images/logo.png";
import btn_menu from "../../media/images/menu.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/actions/lang.action";
import { contactUsAction } from "../../redux/actions/contactUs.action";
import { translate } from "../../helpers/translator";
import CarouselRates from "./CarouselRates";
import CarouselOilPrice from "./CarouselOilPrice.js";

function openNav() {
	var element = document.getElementById("clsBtn");
	document.getElementById("myNav").style.width = "100%";
	element.classList.toggle("show");
}

export const Navbar = () => {
	const { language } = useSelector(state => state.langReducer);

	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const currLang = val => {
		if (val === "EN") {
			return EN;
		} else if (val === "RU") {
			return RU;
		} else if (val === "KZ") {
			return KZ;
		}
	};

	const chooseLanguageHandler = value => {
		dispatch(setLanguage(value));
	};

	const [form, setForm] = useState({
		organization: "",
		employee: "",
		phone: "",
		email: "",
		details: "",
	});

	function changeHandler(e) {
		setForm({ ...form, [e.target.name]: e.target.value });
	}

	function sendForm() {
		dispatch(contactUsAction(form));
	}

	function open() {
		const modal = document.getElementById("myModal");
		modal.style.display = "block";
	}

	function close() {
		const modal = document.getElementById("myModal");
		modal.style.display = "none";
	}

	if (pathname === "/login") {
		return false;
	} else {
		return (
			<div>
				<nav className='navbar navbar-expand-lg'>
					<Link to={"/"} className='menu-button' id='opnBtn' onClick={openNav}>
						<img src={btn_menu} alt='btn_menu' />
					</Link>

					<a href={"/"} className='logo'>
						<img src={logo} alt='logo' />
					</a>

					<div className='navbar-nav mr-auto'>
						<CarouselRates />
						<CarouselOilPrice />
					</div>

					<div className='top-menu'>
						<ul>
							<li className='phone'>
								<a href='tel:8 (7172) 272-424'>+7 (7172) 272-424</a>
							</li>
							<li className='message'>
								<button id='myBtn' onClick={open}>
									{translate("", "message", language)}
								</button>
							</li>

							<li className='language'>
								<img src={currLang(language)} alt='kz' />
								<ul>
									<li onClick={() => chooseLanguageHandler("KZ")}>
										<img src={KZ} alt='kz' />
									</li>
									<li onClick={() => chooseLanguageHandler("RU")}>
										<img src={RU} alt='ru' />
									</li>
									<li onClick={() => chooseLanguageHandler("EN")}>
										<img src={EN} alt='en' />
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>

				<div id='myNav' className='overlay-menu'>
					<div className='contanier-fluid'>
						<div className='col menu'>
							<div className='row'>
								<div className='col-sm-4 col-xs-6 item services'>
									<div className='background' />
									<a href='/service' className='one-link'>
										<h1>{translate("", "service", language)}</h1>
									</a>
								</div>
								<div className='col-sm-4 col-xs-6 item about'>
									<div className='background' />
									<a href='/about_company' className='one-link'>
										<h1>{translate("", "about_company", language)}</h1>
									</a>
								</div>
								<div className='col-sm-4 col-xs-6 item cisterns'>
									<div className='background' />
									<a href='/project' className='one-link'>
										<h1>{translate("", "rolling_stock", language)}</h1>
									</a>
								</div>
								<div className='col-sm-4 col-xs-6 item group'>
									<div className='background' />
									<a href='/company' className='one-link'>
										<h1>{translate("", "group_company", language)}</h1>
									</a>
								</div>
								<div className='col-sm-4 col-xs-6 item news'>
									<div className='background' />
									<a href='/news' className='one-link'>
										<h1>{translate("", "news", language)}</h1>
									</a>
								</div>
								<div className='col-sm-4 col-xs-6 item contacts'>
									<div className='background' />
									<a href='/contacts' className='one-link'>
										<h1>{translate("", "contact", language)}</h1>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id='myModal' className='modal contact-us-modal'>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='exampleModalLabel'>
									{translate("", "write_to_us", language)}
								</h5>
								<span className='close' onClick={close}>
									&times;
								</span>
							</div>
							<div className='modal-body'>
								<div className='form-group'>
									<label htmlFor='companyname'>
										{translate("", "norg", language)}
									</label>
									<input
										type='text'
										name='organization'
										className='form-control'
										id='companyname'
										placeholder='Petroleum LLC'
										onChange={changeHandler}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='employee'>
										{translate("", "cp", language)}
									</label>
									<input
										type='text'
										name='employee'
										className='form-control'
										id='employee'
										placeholder="common', 'Ivan Ivanov'"
										onChange={changeHandler}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='phone'>{translate("", "cn", language)}</label>
									<input
										type='text'
										name='phone'
										className='form-control'
										id='phone'
										placeholder='+7 (777) 777 7777'
										onChange={changeHandler}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='email'>{translate("", "ce", language)}</label>
									<input
										type='text'
										name='email'
										className='form-control'
										id='email'
										placeholder='ivan.ivanov@gmail.com'
										onChange={changeHandler}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='about'>{translate("", "ad", language)}</label>
									<textarea
										name='details'
										className='form-control'
										id='about'
										rows='3'
										onChange={changeHandler}
									></textarea>
								</div>
								<button
									type='submit'
									className='btn btn-primary float-right'
									onClick={sendForm}
								>
									{translate("", "submit", language)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Navbar;
